<template>
  <div>
    <a-modal v-model="visible" title="图库管理" @ok="handleOk">
      <a-upload
        :action="imgurl"
        list-type="picture-card"
        :file-list="fileList"
        @change="handleChange"
        >
        <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">
                上传
            </div>
        </div>
        </a-upload>
    </a-modal>
  </div>
</template>
<script>
import config from '@/http/config'
import matchFun from '@/utils/matchFun'
export default {
  data() {
    return {
      visible: false,
      previewVisible: false,
      previewImage: '',
      imgurl: config.baseImgURL,
      fileList: [],
      houseId: null,
      matchFun
    };
  },
  methods: {
    showModal(item) {
      if (item !== null) {
        this.houseId = item.id
        this.findList()
      }
      this.fileList = []
      this.visible = true;
    },
    handleOk(e) {
        let valData = {
            houseId: this.houseId,
            list: []
        }
        this.fileList.forEach(element => {
            valData.list.push({
                image: element.response.data.qiniuUrl === undefined ? element.url : element.response.data.qiniuUrl
            })
        })
        this.$emit('ok', valData)
        this.visible = false;
        // this.$api.houseImage.save(valData).then(res => {
        //     if (res.status === 0) {
        //         this.$message.success('保存成功')
        //         this.visible = false;
        //     } else {
        //         this.$message.warn(res.msg)
        //     }
        // }).catch(err => {
        //     console.log(err)
        // })
    },
    findList () {
        this.$api.houseImage.list(this.houseId).then(res => {
            if (res.status === 0) {
                res.data.forEach(element => {
                    this.fileList.push(this.matchFun.randomNumFile('图片.jpg', element.image))
                });
                
            } else {
                this.$message.warn(res.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
      console.log(this.fileList)
    }
  },
};
</script>